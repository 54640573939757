<template>
  <modal :show="showModal" @onClose="closeModal">
    <template #header>{{ $t("add-role") }}</template>
    <template #body
      ><role-form @onClose="closeModal" :selectedRole="selectedRole"
    /></template>
  </modal>

  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-roles.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("roles") }}
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <button
          @click="openModal"
          class="bg-WAORANGE-500 hover:bg-WAORANGE-400 mr-2 px-4 py-2 rounded-lg text-white text-xs uppercase"
        >
          + {{ $t("add-role") }}
        </button>
        <role-filter />
      </div>
    </section>

    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <role-table-row></role-table-row>
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredRoles.length">
        <Role
          v-for="role in filteredRoles"
          :key="role.id"
          :role="role"
          @edit="editRole(role)"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";
import RoleFilter from "../components/roles/RoleFilter.vue";
import RoleTableRow from "../components/roles/RoleTableRow.vue";
import Role from "../components/roles/Role.vue";
import RoleForm from "../components/roles/RoleForm.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    RoleFilter,
    RoleTableRow,
    Role,
    Loader,
    RoleForm,
    Modal,
  },
  computed: {
    ...mapGetters("roles", ["loading", "list", "error", "filteredRoles"]),
  },
  data() {
    return {
      showModal: false,
      selectedRole: null,
      check: false,
    };
  },
  methods: {
    ...mapActions("roles", ["getRoles"]),
    editRole(role) {
      this.selectedRole = role;
      this.showModal = true;
    },
    openModal() {
      this.selectedRole = null;
      this.showModal = true;
    },
    closeModal() {
      this.selectedRole = null;
      this.showModal = false;
    },
  },
  async created() {
    await this.getRoles();
  },
};
</script>
