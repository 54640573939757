<template>
  <form @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('role-name')"
        type="text"
        v-model="name"
      />
    </div>

    <div class="my-2">
      <div class="flex items-center text-sm justify-between px-2 mb-2">
        <p class="text-gray-600 text-sm font-semibold">{{ $t("modules") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div
            class="text-gray-600 text-sm w-12 sm:w-24 truncate font-semibold"
          >
            {{ $t("user") }}
          </div>
          <div
            class="text-gray-600 text-sm font-semibold w-12 sm:w-24 truncate"
          >
            {{ $t("group-user") }}
          </div>
          <div
            class="text-gray-600 text-sm w-12 sm:w-24 truncate font-semibold"
          >
            {{ $t("edit") }}
          </div>
          <div
            class="text-gray-600 text-sm w-12 sm:w-24 truncate font-semibold"
          >
            {{ $t("admin") }}
          </div>
        </div>
      </div>
      <div
        class="role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("submissions") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="submission.user"
              @onChange="onChange"
              name="submission"
              opt="user"
            ></check-box>
          </div>

          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="submission.group"
              @onChange="onChange"
              name="submission"
              opt="group"
            ></check-box>
          </div>

          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="submission.edit"
              @onChange="onChange"
              name="submission"
              opt="edit"
            ></check-box>
          </div>

          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="submission.admin"
              @onChange="onChange"
              name="submission"
              opt="admin"
            ></check-box>
          </div>
        </div>
      </div>
      <div
        class="document role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("documents") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="document.user"
              @onChange="onChange"
              name="document"
              opt="user"
            ></check-box>
          </div>

          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              style="visibility: hidden !important; opacity: 0"
              @onChange="onChange"
              name="document"
              opt="group"
            ></check-box>
          </div>
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="document.edit"
              @onChange="onChange"
              name="document"
              opt="edit"
            ></check-box>
          </div>

          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="document.admin"
              @onChange="onChange"
              name="document"
              opt="admin"
            ></check-box>
          </div>
        </div>
      </div>

      <div
        class="role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("group") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="group.user"
              @onChange="onChange"
              name="group"
              opt="user"
            ></check-box>
          </div>
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              style="visibility: hidden !important; opacity: 0"
              @onChange="onChange"
              name="group"
              opt="group"
            ></check-box>
          </div>
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="group.edit"
              @onChange="onChange"
              name="group"
              opt="edit"
            ></check-box>
          </div>
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="group.admin"
              @onChange="onChange"
              name="group"
              opt="admin"
            ></check-box>
          </div>
        </div>
      </div>

      <div
        class="role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("forms") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="form.admin"
              @onChange="onChange"
              name="form"
              opt="admin"
            ></check-box>
          </div>
        </div>
      </div>
      <div
        class="role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("users") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="user.admin"
              @onChange="onChange"
              name="user"
              opt="admin"
            ></check-box>
          </div>
        </div>
      </div>

      <div
        class="role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("roles") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div class="w-12 sm:w-24 flex justify-center">
            <check-box
              :value="role.admin"
              @onChange="onChange"
              name="role"
              opt="admin"
            ></check-box>
          </div>
        </div>
      </div>

      <div
        class="role py-1.5 my-1 rounded flex items-center justify-between px-2"
      >
        <p class="text-gray-600 text-sm">{{ $t("email-notifications") }}</p>
        <div class="flex gap-x-3 text-sm text-center">
          <div
            class="w-12 sm:w-24 flex-col items-center flex justify-center gap-2"
          >
            <div>
              <check-box
                :value="emailNotification.editNew"
                @onChange="onChange"
                name="emailNotification"
                opt="editNew"
              ></check-box>

              <span class="text-xs text-gray-500">{{
                $t("new")
              }}</span>
            </div>
            <div>
              <check-box
                :value="emailNotification.editUpdate"
                @onChange="onChange"
                name="emailNotification"
                opt="editUpdate"
              ></check-box>

              <span
                class="text-xs text-gray-500 truncate"
                >{{ $t("updated") }}</span
              >
            </div>
          </div>
          <div
            class="w-12 sm:w-24 flex-col items-center flex justify-center gap-2"
          >
            <div>
              <check-box
                :value="emailNotification.adminNew"
                @onChange="onChange"
                name="emailNotification"
                opt="adminNew"
              ></check-box>

              <span class="text-xs text-gray-500">{{
                $t("new")
              }}</span>
            </div>
            <div>
              <check-box
                :value="emailNotification.adminUpdate"
                @onChange="onChange"
                name="emailNotification"
                opt="adminUpdate"
              ></check-box>
              <span class="text-xs text-gray-500">{{
                $t("updated")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex relative items-center justify-end pt-2 gap-x-3">
      <button
        :disabled="loading || deleting"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          this.selectedRole
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? $t("adding")
            : $t("add-role")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import http from "../../services/httpClient";
import { mapMutations } from "vuex";

import CheckBox from "@/components/forms/CheckBox";

export default {
  components: { CheckBox },
  data() {
    return {
      loading: false,
      deleting: false,
      showConfirm: false,
      error: null,
      name: this.selectedRole ? this.selectedRole.name : "",
      form: {
        user: this.selectedRole ? this.selectedRole?.form?.user : false,
        edit: this.selectedRole ? this.selectedRole?.form?.edit : false,
        admin: this.selectedRole ? this.selectedRole?.form?.admin : false,
      },
      user: {
        user: this.selectedRole ? this.selectedRole?.user?.user : false,
        edit: this.selectedRole ? this.selectedRole?.user?.edit : false,
        admin: this.selectedRole ? this.selectedRole?.user?.admin : false,
      },
      group: {
        user: this.selectedRole ? this.selectedRole?.group?.user : false,
        edit: this.selectedRole ? this.selectedRole?.group?.edit : false,
        admin: this.selectedRole ? this.selectedRole?.group?.admin : false,
      },
      role: {
        user: this.selectedRole ? this.selectedRole?.role?.user : false,
        edit: this.selectedRole ? this.selectedRole?.role?.edit : false,
        admin: this.selectedRole ? this.selectedRole?.role?.admin : false,
      },
      emailNotification: {
        editNew: !!this.selectedRole?.emailNotification?.edit?.new,
        editUpdate: !!this.selectedRole?.emailNotification?.edit?.update,
        adminNew: !!this.selectedRole?.emailNotification?.admin?.new,
        adminUpdate: !!this.selectedRole?.emailNotification?.admin?.update,
      },
      submission: {
        user: this.selectedRole ? this.selectedRole?.submission?.user : false,
        group: this.selectedRole ? this.selectedRole?.submission?.group : false,
        edit: this.selectedRole ? this.selectedRole?.submission?.edit : false,
        admin: this.selectedRole ? this.selectedRole?.submission?.admin : false,
      },
      document: {
        user: this.selectedRole ? this.selectedRole?.document?.user : false,
        edit: this.selectedRole ? this.selectedRole?.document?.edit : false,
        admin: this.selectedRole ? this.selectedRole?.document?.admin : false,
      },
    };
  },
  emits: ["onClose"],
  methods: {
    ...mapMutations("roles", ["addRole", "updateRole", "removeRole"]),
    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.name) return (this.error = "Please fill in all fields");

      try {
        this.loading = true;

        const dto = {
          name: this.name,
          form: this.form,
          submission: this.submission,
          user: this.user,
          group: this.group,
          role: this.role,
          document: this.document,
          emailNotification: {
            edit: {
              new: this.emailNotification.editNew,
              update: this.emailNotification.editUpdate,
            },
            admin: {
              new: this.emailNotification.adminNew,
              update: this.emailNotification.adminUpdate,
            },
          },
        };

        if (this.selectedRole) {
          const { data } = await http.put(
            `/admin/roles/${this.selectedRole._id}`,
            dto
          );

          this.updateRole(data);
        } else {
          const { data } = await http.post(`/admin/roles`, dto);
          this.addRole(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err?.response && err.response?.data?.error
            ? err.response?.data?.error
            : err?.error || err.message;
      }
    },

    async deleteRole(id) {
      if (this.error) this.error = null;

      try {
        this.deleting = true;

        await http.delete(`/admin/roles/${this.selectedRole._id}`);

        this.deleting = false;
        this.removeRole(id);
        this.$emit("onClose");
      } catch (err) {
        this.deleting = false;
        this.error =
          err.response && err.response?.data?.error
            ? err.response?.data?.error
            : err.error || err.message;
      }
    },

    onChange(name, key, changedValue) {
      console.log(name, key, changedValue);

      this[name][key] = changedValue;
    },

    withThreeDot(data, limit = 12) {
      return data.length >= limit ? data.substr(0, limit) + ".." : data;
    },
  },
  props: ["selectedRole"],
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

.role {
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.role:last-child {
  border: none;
}

@media screen and (min-width: 640px) {
  form {
    width: 635px;
  }
}
</style>
