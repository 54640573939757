<template>
  <div class="relative">
    <button @click="toggleFilter" class="flex items-center">
      <img src="/icon-filters.svg" alt="filter" />
    </button>
    <Transition name="slide-down-fade">
      <div class="absolute right-0 top-10 z-10 filter_class" v-if="showFilter">
        <div
          class="
            flex-col
            items-center
            justify-between
            gap-2
            bg-white
            p-3
            rounded
            shadow-md
          "
        >
          <select-input
            :data="getInputData('name')"
            :placeholder="$t('name')"
            :filterMode="true"
            v-model="name"
            size="small"
            width="full"
          />
        </div></div
    ></Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  computed: {
    ...mapGetters("roles", ["list"]),
  },
  components: { SelectInput },

  data() {
    return {
      name: "",
      status: "",
      filterData: { name: "", status: "" },
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      showFilter: false,
    };
  },
  methods: {
    ...mapActions("roles", ["filterRole"]),
    getInputData(name) {
      return this.list.map((group) => ({ id: group.id, value: group[name] }));
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showFilter = false;
      }
    },
  },
  watch: {
    name(newVal) {
      this.filterData = { ...this.filterData, name: newVal };
      this.filterRole(this.filterData);
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
