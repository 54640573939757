<template>
  <button
    class="block w-full cursor-default bg-white max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-sm shadow-gray-100 lg:px-4"
  >
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <div class="flex pr-1 w-44 md:w-64">
          <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
            {{ role?.name }}
          </p>
        </div>
        <div class="w-36 px-1 md:w-80 hidden sm:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin">
            {{ role?.users?.length }}
          </p>
        </div>
      </div>
      <div class="relative flex justify-end w-20 sm:flex">
        <!-- edit dropdown -->
        <option-dropdown-new
          @btnClick="openOptionDropDown(role)"
          @onClose="closeOptionDropDown"
          :show="
            showOptionDropdown.status && showOptionDropdown.id === role._id
          "
        >
          <button
            @click="$emit('edit', role)"
            class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
          >
            {{ $t("edit") }}
          </button>
          <button
            @click="openConfirmModal(role._id)"
            class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
          >
            {{ $t("delete") }}
          </button>
        </option-dropdown-new>
        <div
          class="absolute -bottom-16 w-80"
          v-if="showConfirm.status && showConfirm.id === role._id"
        >
          <confirm-modal
            :show="showConfirm.status && showConfirm.id === role._id"
            @no="closeConfirmModal"
            @yes="confirmDelete(role._id)"
          ></confirm-modal>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";
import ConfirmModal from "../ConfirmModal.vue";

import httpClient from "../../services/httpClient";
import { mapMutations } from "vuex";

export default {
  components: { OptionDropdownNew, ConfirmModal },

  data() {
    return {
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },

  methods: {
    ...mapMutations("roles", ["removeRole"]),

    openOptionDropDown(role) {
      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = role._id;
    },

    async deleteRole(id) {
      try {
        this.deleting = true;

        await httpClient.delete(`/admin/roles/${this.role._id}`);

        this.deleting = false;
        this.removeRole(id);
      } catch (err) {
        this.deleting = false;
        console.error(err);
      }
    },

    closeOptionDropDown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      this.deleteRole(id);
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },
  },

  props: ["role", "sub"],

  emits: ["edit"],

  mounted() {
    document.addEventListener("click", (e) => {
      this.closeOptionDropDown(e);
      this.closeConfirmModal(e);
    });
  },

  beforeUnmount() {
    document.removeEventListener("click", (e) => {
      this.closeOptionDropDown(e);
      this.closeConfirmModal(e);
    });
  },
};
</script>
